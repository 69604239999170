// ** Initial State
const initialState = {
    menuItems: []
}

const menuReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_MENU_ITEMS':
            return { ...state, data: action.data }
        default:
            return state
    }
}

export default menuReducer
