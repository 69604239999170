import axios from 'axios'

// ** Get Profile Item
export const getProfileItem = () => {
    return dispatch => {
        return axios.get('/api/v3/user/profile').then(response => {
            dispatch({
                type: 'GET_PROFILE_ITEM',
                data: response.data
            })
            return response.data
        })
    }
}
// ** Get Pubic Profile Item
export const getPublicProfileItem = ( { slug } ) => {
    return dispatch => {
        return axios.get(`/api/v3/user/profile/${slug}`).then(response => {
            dispatch({
                type: 'GET_PROFILE_ITEM',
                data: response.data
            })
            return response.data
        })
    }
}
export const deleteProfileIcon = ({ id }, data) => {
    return dispatch => {
        return axios.delete(`/api/v3/user/profile/picture`, { params: { userId: id }, data })
    }
}
// ** Get Profile Detail
export const getProfileDetail = ({ id }) => {
    return async dispatch => {
        return await axios.get(`/api/v3/user/profile`, { params : { userId: id }} ).then(response => response.data)
    }
}
// ** Update Profile Item
export const updateProfile = ({ id }, data) => {

    return dispatch => {
        return axios.put(`/api/v3/user/profile/update`, data, { params : {userId: id }})
    }
}

export const updateProfilePhoto = ({ id }, data) => {
    return dispatch => {
        const form = new FormData()
        form.append('file', data.file)
        form.append('image', data.image)
        return axios.put(`/api/v3/user/profile/picture`, form, { params: { userId: id }, headers: { "Content-Type": "multipart/form-data" } })
    }
}
export const updateAccount = ({ id }, data) => {
    return dispatch => {
        return axios.put(`/api/v3/user/account/update`, data, { params : {userId: id }})
    }
}

export const postExitSurvey = ({ id }, data) => {

    return dispatch => {
        return axios.post(`/api/v3/user/exitSurvey`, data, { params : {userId: id }})
    }
}