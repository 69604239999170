// ** Initial State
const initialState = {
    isPublic: true
}
const publicProfileReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_IS_PUBLIC':
            return {
                ...state,
                isPublic: action.data
            }
        default:
            return state
    }
}

export default publicProfileReducer
