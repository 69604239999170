// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import menu from './menu'
import user from './user'
import profile from './profile'
import publicProfile from './public-profile'
import others from './others'
import signUp from './sign-up'
import question from './question'

const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  menu,
  user,
  profile,
  publicProfile,
  others,
  signUp,
  question
})

export default rootReducer
